export const icons = [
  { id: 'fas fa-ad', name: 'ad' },
  { id: 'fas fa-address-book', name: 'address-book' },
  { id: 'fas fa-address-card', name: 'address-card' },
  { id: 'fas fa-adjust', name: 'adjust' },
  { id: 'fas fa-air-freshener', name: 'air-freshener' },
  { id: 'fas fa-align-center', name: 'align-center' },
  { id: 'fas fa-align-justify', name: 'align-justify' },
  { id: 'fas fa-align-left', name: 'align-left' },
  { id: 'fas fa-align-right', name: 'align-right' },
  { id: 'fas fa-allergies', name: 'allergies' },
  { id: 'fas fa-ambulance', name: 'ambulance' },
  {
    id: 'fas fa-american-sign-language-interpreting',
    name: 'american-sign-language-interpreting',
  },
  { id: 'fas fa-anchor', name: 'anchor' },
  { id: 'fas fa-angle-double-down', name: 'angle-double-down' },
  { id: 'fas fa-angle-double-left', name: 'angle-double-left' },
  { id: 'fas fa-angle-double-right', name: 'angle-double-right' },
  { id: 'fas fa-angle-double-up', name: 'angle-double-up' },
  { id: 'fas fa-angle-down', name: 'angle-down' },
  { id: 'fas fa-angle-left', name: 'angle-left' },
  { id: 'fas fa-angle-right', name: 'angle-right' },
  { id: 'fas fa-angle-up', name: 'angle-up' },
  { id: 'fas fa-angry', name: 'angry' },
  { id: 'fas fa-ankh', name: 'ankh' },
  { id: 'fas fa-apple-alt', name: 'apple-alt' },
  { id: 'fas fa-archive', name: 'archive' },
  { id: 'fas fa-archway', name: 'archway' },
  { id: 'fas fa-asterisk', name: 'asterisk' },
  { id: 'fas fa-at', name: 'at' },
  { id: 'fas fa-atlas', name: 'atlas' },
  { id: 'fas fa-atom', name: 'atom' },
  { id: 'fas fa-audio-description', name: 'audio-description' },
  { id: 'fas fa-award', name: 'award' },
  { id: 'fas fa-band-aid', name: 'band-aid' },
  { id: 'fas fa-barcode', name: 'barcode' },
  { id: 'fas fa-bars', name: 'bars' },
  { id: 'fas fa-baseball-ball', name: 'baseball-ball' },
  { id: 'fas fa-basketball-ball', name: 'basketball-ball' },
  { id: 'fas fa-bath', name: 'bath' },
  { id: 'fas fa-bell', name: 'bell' },
  { id: 'fas fa-bell-slash', name: 'bell-slash' },
  { id: 'fas fa-bezier-curve', name: 'bezier-curve' },
  { id: 'fas fa-bicycle', name: 'bicycle' },
  { id: 'fas fa-biking', name: 'biking' },
  { id: 'fas fa-binoculars', name: 'binoculars' },
  { id: 'fas fa-biohazard', name: 'biohazard' },
  { id: 'fas fa-birthday-cake', name: 'birthday-cake' },
  { id: 'fas fa-blender', name: 'blender' },
  { id: 'fas fa-blender-phone', name: 'blender-phone' },
  { id: 'fas fa-blind', name: 'blind' },
  { id: 'fas fa-blog', name: 'blog' },
  { id: 'fas fa-bold', name: 'bold' },
  { id: 'fas fa-bolt', name: 'bolt' },
  { id: 'fas fa-bomb', name: 'bomb' },
  { id: 'fas fa-bone', name: 'bone' },
  { id: 'fas fa-bong', name: 'bong' },
  { id: 'fas fa-book', name: 'book' },
  { id: 'fas fa-book-dead', name: 'book-dead' },
  { id: 'fas fa-book-medical', name: 'book-medical' },
  { id: 'fas fa-book-open', name: 'book-open' },
  { id: 'fas fa-book-reader', name: 'book-reader' },
  { id: 'fas fa-bookmark', name: 'bookmark' },
  { id: 'fas fa-border-all', name: 'border-all' },
  { id: 'fas fa-border-none', name: 'border-none' },
  { id: 'fas fa-border-style', name: 'border-style' },
  { id: 'fas fa-bowling-ball', name: 'bowling-ball' },
  { id: 'fas fa-box', name: 'box' },
  { id: 'fas fa-box-open', name: 'box-open' },
  { id: 'fas fa-box-tissue', name: 'box-tissue' },
  { id: 'fas fa-boxes', name: 'boxes' },
  { id: 'fas fa-braille', name: 'braille' },
  { id: 'fas fa-brain', name: 'brain' },
  { id: 'fas fa-bread-slice', name: 'bread-slice' },
  { id: 'fas fa-briefcase', name: 'briefcase' },
  { id: 'fas fa-briefcase-medical', name: 'briefcase-medical' },
  { id: 'fas fa-broadcast-tower', name: 'broadcast-tower' },
  { id: 'fas fa-broom', name: 'broom' },
  { id: 'fas fa-brush', name: 'brush' },
  { id: 'fas fa-bug', name: 'bug' },
  { id: 'fas fa-building', name: 'building' },
  { id: 'fas fa-burn', name: 'burn' },
  { id: 'fas fa-bus', name: 'bus' },
  { id: 'fas fa-bus-alt', name: 'bus-alt' },
  { id: 'fas fa-business-time', name: 'business-time' },
  { id: 'fas fa-calculator', name: 'calculator' },
  { id: 'fas fa-calendar', name: 'calendar' },
  { id: 'fas fa-calendar-alt', name: 'calendar-alt' },
  { id: 'fas fa-calendar-check', name: 'calendar-check' },
  { id: 'fas fa-calendar-day', name: 'calendar-day' },
  { id: 'fas fa-calendar-minus', name: 'calendar-minus' },
  { id: 'fas fa-calendar-plus', name: 'calendar-plus' },
  { id: 'fas fa-calendar-times', name: 'calendar-times' },
  { id: 'fas fa-calendar-week', name: 'calendar-week' },
  { id: 'fas fa-camera', name: 'camera' },
  { id: 'fas fa-camera-retro', name: 'camera-retro' },
  { id: 'fas fa-campground', name: 'campground' },
  { id: 'fas fa-cannabis', name: 'cannabis' },
  { id: 'fas fa-capsules', name: 'capsules' },
  { id: 'fas fa-cash-register', name: 'cash-register' },
  { id: 'fas fa-cat', name: 'cat' },
  { id: 'fas fa-certificate', name: 'certificate' },
  { id: 'fas fa-chair', name: 'chair' },
  { id: 'fas fa-chalkboard', name: 'chalkboard' },
  { id: 'fas fa-chalkboard-teacher', name: 'chalkboard-teacher' },
  { id: 'fas fa-charging-station', name: 'charging-station' },
  { id: 'fas fa-chart-area', name: 'chart-area' },
  { id: 'fas fa-chart-bar', name: 'chart-bar' },
  { id: 'fas fa-chart-line', name: 'chart-line' },
  { id: 'fas fa-chart-pie', name: 'chart-pie' },
  { id: 'fas fa-check', name: 'check' },
  { id: 'fas fa-chess', name: 'chess' },
  { id: 'fas fa-chess-bishop', name: 'chess-bishop' },
  { id: 'fas fa-chess-board', name: 'chess-board' },
  { id: 'fas fa-chess-king', name: 'chess-king' },
  { id: 'fas fa-chess-knight', name: 'chess-knight' },
  { id: 'fas fa-chess-pawn', name: 'chess-pawn' },
  { id: 'fas fa-chess-queen', name: 'chess-queen' },
  { id: 'fas fa-chess-rook', name: 'chess-rook' },
  { id: 'fas fa-child', name: 'child' },
  { id: 'fas fa-circle', name: 'circle' },
  { id: 'fas fa-circle-notch', name: 'circle-notch' },
  { id: 'fas fa-city', name: 'city' },
  { id: 'fas fa-clinic-medical', name: 'clinic-medical' },
  { id: 'fas fa-clipboard', name: 'clipboard' },
  { id: 'fas fa-clipboard-check', name: 'clipboard-check' },
  { id: 'fas fa-clipboard-list', name: 'clipboard-list' },
  { id: 'fas fa-clock', name: 'clock' },
  { id: 'fas fa-clone', name: 'clone' },
  { id: 'fas fa-closed-captioning', name: 'closed-captioning' },
  { id: 'fas fa-cloud', name: 'cloud' },
  { id: 'fas fa-cloud-download-alt', name: 'cloud-download-alt' },
  { id: 'fas fa-cloud-meatball', name: 'cloud-meatball' },
  { id: 'fas fa-cloud-moon', name: 'cloud-moon' },
  { id: 'fas fa-cloud-moon-rain', name: 'cloud-moon-rain' },
  { id: 'fas fa-cloud-rain', name: 'cloud-rain' },
  { id: 'fas fa-cloud-showers-heavy', name: 'cloud-showers-heavy' },
  { id: 'fas fa-cloud-sun', name: 'cloud-sun' },
  { id: 'fas fa-cloud-sun-rain', name: 'cloud-sun-rain' },
  { id: 'fas fa-cloud-upload-alt', name: 'cloud-upload-alt' },
  { id: 'fas fa-cocktail', name: 'cocktail' },
  { id: 'fas fa-code', name: 'code' },
  { id: 'fas fa-code-branch', name: 'code-branch' },
  { id: 'fas fa-coffee', name: 'coffee' },
  { id: 'fas fa-cog', name: 'cog' },
  { id: 'fas fa-cogs', name: 'cogs' },
  { id: 'fas fa-coins', name: 'coins' },
  { id: 'fas fa-columns', name: 'columns' },
  { id: 'fas fa-compress', name: 'compress' },
  { id: 'fas fa-compress-alt', name: 'compress-alt' },
  { id: 'fas fa-compress-arrows-alt', name: 'compress-arrows-alt' },
  { id: 'fas fa-concierge-bell', name: 'concierge-bell' },
  { id: 'fas fa-cookie', name: 'cookie' },
  { id: 'fas fa-cookie-bite', name: 'cookie-bite' },
  { id: 'fas fa-copy', name: 'copy' },
  { id: 'fas fa-copyright', name: 'copyright' },
  { id: 'fas fa-couch', name: 'couch' },
  { id: 'fas fa-credit-card', name: 'credit-card' },
  { id: 'fas fa-crop', name: 'crop' },
  { id: 'fas fa-crop-alt', name: 'crop-alt' },
  { id: 'fas fa-cross', name: 'cross' },
  { id: 'fas fa-crosshairs', name: 'crosshairs' },
  { id: 'fas fa-crow', name: 'crow' },
  { id: 'fas fa-crown', name: 'crown' },
  { id: 'fas fa-crutch', name: 'crutch' },
  { id: 'fas fa-cube', name: 'cube' },
  { id: 'fas fa-cubes', name: 'cubes' },
  { id: 'fas fa-cut', name: 'cut' },
  { id: 'fas fa-database', name: 'database' },
  { id: 'fas fa-deaf', name: 'deaf' },
  { id: 'fas fa-democrat', name: 'democrat' },
  { id: 'fas fa-desktop', name: 'desktop' },
  { id: 'fas fa-dharmachakra', name: 'dharmachakra' },
  { id: 'fas fa-diagnoses', name: 'diagnoses' },
  { id: 'fas fa-dice', name: 'dice' },
  { id: 'fas fa-dice-d20', name: 'dice-d20' },
  { id: 'fas fa-dice-d6', name: 'dice-d6' },
  { id: 'fas fa-dice-five', name: 'dice-five' },
  { id: 'fas fa-dice-four', name: 'dice-four' },
  { id: 'fas fa-dice-one', name: 'dice-one' },
  { id: 'fas fa-dice-six', name: 'dice-six' },
  { id: 'fas fa-dice-three', name: 'dice-three' },
  { id: 'fas fa-dice-two', name: 'dice-two' },
  { id: 'fas fa-digital-tachograph', name: 'digital-tachograph' },
  { id: 'fas fa-directions', name: 'directions' },
  { id: 'fas fa-disease', name: 'disease' },
  { id: 'fas fa-divide', name: 'divide' },
  { id: 'fas fa-dizzy', name: 'dizzy' },
  { id: 'fas fa-dna', name: 'dna' },
  { id: 'fas fa-dog', name: 'dog' },
  { id: 'fas fa-dollar-sign', name: 'dollar-sign' },
  { id: 'fas fa-dolly', name: 'dolly' },
  { id: 'fas fa-dolly-flatbed', name: 'dolly-flatbed' },
  { id: 'fas fa-donate', name: 'donate' },
  { id: 'fas fa-door-closed', name: 'door-closed' },
  { id: 'fas fa-door-open', name: 'door-open' },
  { id: 'fas fa-dot-circle', name: 'dot-circle' },
  { id: 'fas fa-dove', name: 'dove' },
  { id: 'fas fa-download', name: 'download' },
  { id: 'fas fa-drafting-compass', name: 'drafting-compass' },
  { id: 'fas fa-dragon', name: 'dragon' },
  { id: 'fas fa-draw-polygon', name: 'draw-polygon' },
  { id: 'fas fa-drum', name: 'drum' },
  { id: 'fas fa-drum-steelpan', name: 'drum-steelpan' },
  { id: 'fas fa-drumstick-bite', name: 'drumstick-bite' },
  { id: 'fas fa-dumbbell', name: 'dumbbell' },
  { id: 'fas fa-dumpster', name: 'dumpster' },
  { id: 'fas fa-dumpster-fire', name: 'dumpster-fire' },
  { id: 'fas fa-dungeon', name: 'dungeon' },
  { id: 'fas fa-edit', name: 'edit' },
  { id: 'fas fa-egg', name: 'egg' },
  { id: 'fas fa-eject', name: 'eject' },
  { id: 'fas fa-ellipsis-h', name: 'ellipsis-h' },
  { id: 'fas fa-ellipsis-v', name: 'ellipsis-v' },
  { id: 'fas fa-envelope', name: 'envelope' },
  { id: 'fas fa-envelope-open', name: 'envelope-open' },
  { id: 'fas fa-envelope-open-text', name: 'envelope-open-text' },
  { id: 'fas fa-envelope-square', name: 'envelope-square' },
  { id: 'fas fa-equals', name: 'equals' },
  { id: 'fas fa-eraser', name: 'eraser' },
  { id: 'fas fa-ethernet', name: 'ethernet' },
  { id: 'fas fa-euro-sign', name: 'euro-sign' },
  { id: 'fas fa-exchange-alt', name: 'exchange-alt' },
  { id: 'fas fa-exclamation', name: 'exclamation' },
  { id: 'fas fa-exclamation-circle', name: 'exclamation-circle' },
  { id: 'fas fa-exclamation-triangle', name: 'exclamation-triangle' },
  { id: 'fas fa-expand', name: 'expand' },
  { id: 'fas fa-expand-alt', name: 'expand-alt' },
  { id: 'fas fa-expand-arrows-alt', name: 'expand-arrows-alt' },
  { id: 'fas fa-external-link-alt', name: 'external-link-alt' },
  { id: 'fas fa-external-link-square-alt', name: 'external-link-square-alt' },
  { id: 'fas fa-eye', name: 'eye' },
  { id: 'fas fa-eye-dropper', name: 'eye-dropper' },
  { id: 'fas fa-eye-slash', name: 'eye-slash' },
  { id: 'fas fa-fan', name: 'fan' },
  { id: 'fas fa-fast-backward', name: 'fast-backward' },
  { id: 'fas fa-fast-forward', name: 'fast-forward' },
  { id: 'fas fa-faucet', name: 'faucet' },
  { id: 'fas fa-fax', name: 'fax' },
  { id: 'fas fa-feather', name: 'feather' },
  { id: 'fas fa-feather-alt', name: 'feather-alt' },
  { id: 'fas fa-female', name: 'female' },
  { id: 'fas fa-fighter-jet', name: 'fighter-jet' },
  { id: 'fas fa-file', name: 'file' },
  { id: 'fas fa-file-alt', name: 'file-alt' },
  { id: 'fas fa-file-archive', name: 'file-archive' },
  { id: 'fas fa-file-audio', name: 'file-audio' },
  { id: 'fas fa-file-code', name: 'file-code' },
  { id: 'fas fa-file-contract', name: 'file-contract' },
  { id: 'fas fa-file-csv', name: 'file-csv' },
  { id: 'fas fa-file-download', name: 'file-download' },
  { id: 'fas fa-file-excel', name: 'file-excel' },
  { id: 'fas fa-file-export', name: 'file-export' },
  { id: 'fas fa-file-image', name: 'file-image' },
  { id: 'fas fa-file-import', name: 'file-import' },
  { id: 'fas fa-file-invoice', name: 'file-invoice' },
  { id: 'fas fa-file-invoice-dollar', name: 'file-invoice-dollar' },
  { id: 'fas fa-file-medical', name: 'file-medical' },
  { id: 'fas fa-file-medical-alt', name: 'file-medical-alt' },
  { id: 'fas fa-file-pdf', name: 'file-pdf' },
  { id: 'fas fa-file-powerpoint', name: 'file-powerpoint' },
  { id: 'fas fa-file-prescription', name: 'file-prescription' },
  { id: 'fas fa-file-signature', name: 'file-signature' },
  { id: 'fas fa-file-upload', name: 'file-upload' },
  { id: 'fas fa-file-video', name: 'file-video' },
  { id: 'fas fa-file-word', name: 'file-word' },
  { id: 'fas fa-fill', name: 'fill' },
  { id: 'fas fa-fill-drip', name: 'fill-drip' },
  { id: 'fas fa-film', name: 'film' },
  { id: 'fas fa-filter', name: 'filter' },
  { id: 'fas fa-fingerprint', name: 'fingerprint' },
  { id: 'fas fa-fire', name: 'fire' },
  { id: 'fas fa-fire-alt', name: 'fire-alt' },
  { id: 'fas fa-fire-extinguisher', name: 'fire-extinguisher' },
  { id: 'fas fa-first-aid', name: 'first-aid' },
  { id: 'fas fa-fish', name: 'fish' },
  { id: 'fas fa-fist-raised', name: 'fist-raised' },
  { id: 'fas fa-flag', name: 'flag' },
  { id: 'fas fa-flag-checkered', name: 'flag-checkered' },
  { id: 'fas fa-flag-usa', name: 'flag-usa' },
  { id: 'fas fa-flask', name: 'flask' },
  { id: 'fas fa-flushed', name: 'flushed' },
  { id: 'fas fa-folder', name: 'folder' },
  { id: 'fas fa-folder-minus', name: 'folder-minus' },
  { id: 'fas fa-folder-open', name: 'folder-open' },
  { id: 'fas fa-folder-plus', name: 'folder-plus' },
  { id: 'fas fa-font', name: 'font' },
  { id: 'fas fa-football-ball', name: 'football-ball' },
  { id: 'fas fa-forward', name: 'forward' },
  { id: 'fas fa-frog', name: 'frog' },
  { id: 'fas fa-frown', name: 'frown' },
  { id: 'fas fa-frown-open', name: 'frown-open' },
  { id: 'fas fa-funnel-dollar', name: 'funnel-dollar' },
  { id: 'fas fa-futbol', name: 'futbol' },
  { id: 'fas fa-gamepad', name: 'gamepad' },
  { id: 'fas fa-gas-pump', name: 'gas-pump' },
  { id: 'fas fa-gavel', name: 'gavel' },
  { id: 'fas fa-gem', name: 'gem' },
  { id: 'fas fa-genderless', name: 'genderless' },
  { id: 'fas fa-ghost', name: 'ghost' },
  { id: 'fas fa-gift', name: 'gift' },
  { id: 'fas fa-gifts', name: 'gifts' },
  { id: 'fas fa-glass-cheers', name: 'glass-cheers' },
  { id: 'fas fa-glass-martini', name: 'glass-martini' },
  { id: 'fas fa-glass-martini-alt', name: 'glass-martini-alt' },
  { id: 'fas fa-glass-whiskey', name: 'glass-whiskey' },
  { id: 'fas fa-glasses', name: 'glasses' },
  { id: 'fas fa-globe', name: 'globe' },
  { id: 'fas fa-globe-africa', name: 'globe-africa' },
  { id: 'fas fa-globe-americas', name: 'globe-americas' },
  { id: 'fas fa-globe-asia', name: 'globe-asia' },
  { id: 'fas fa-globe-europe', name: 'globe-europe' },
  { id: 'fas fa-golf-ball', name: 'golf-ball' },
  { id: 'fas fa-gopuram', name: 'gopuram' },
  { id: 'fas fa-graduation-cap', name: 'graduation-cap' },
  { id: 'fas fa-greater-than', name: 'greater-than' },
  { id: 'fas fa-greater-than-equal', name: 'greater-than-equal' },
  { id: 'fas fa-grimace', name: 'grimace' },
  { id: 'fas fa-grin', name: 'grin' },
  { id: 'fas fa-grin-alt', name: 'grin-alt' },
  { id: 'fas fa-grin-beam', name: 'grin-beam' },
  { id: 'fas fa-grin-beam-sweat', name: 'grin-beam-sweat' },
  { id: 'fas fa-grin-hearts', name: 'grin-hearts' },
  { id: 'fas fa-grin-squint', name: 'grin-squint' },
  { id: 'fas fa-grin-squint-tears', name: 'grin-squint-tears' },
  { id: 'fas fa-grin-stars', name: 'grin-stars' },
  { id: 'fas fa-grin-tears', name: 'grin-tears' },
  { id: 'fas fa-grin-tongue', name: 'grin-tongue' },
  { id: 'fas fa-grin-tongue-squint', name: 'grin-tongue-squint' },
  { id: 'fas fa-grin-tongue-wink', name: 'grin-tongue-wink' },
  { id: 'fas fa-grin-wink', name: 'grin-wink' },
  { id: 'fas fa-grip-horizontal', name: 'grip-horizontal' },
  { id: 'fas fa-grip-lines', name: 'grip-lines' },
  { id: 'fas fa-grip-lines-vertical', name: 'grip-lines-vertical' },
  { id: 'fas fa-grip-vertical', name: 'grip-vertical' },
  { id: 'fas fa-guitar', name: 'guitar' },
  { id: 'fas fa-h-square', name: 'h-square' },
  { id: 'fas fa-hamburger', name: 'hamburger' },
  { id: 'fas fa-hammer', name: 'hammer' },
  { id: 'fas fa-hamsa', name: 'hamsa' },
  { id: 'fas fa-hand-holding', name: 'hand-holding' },
  { id: 'fas fa-hand-holding-heart', name: 'hand-holding-heart' },
  { id: 'fas fa-hand-holding-medical', name: 'hand-holding-medical' },
  { id: 'fas fa-hand-holding-usd', name: 'hand-holding-usd' },
  { id: 'fas fa-hand-holding-water', name: 'hand-holding-water' },
  { id: 'fas fa-hand-lizard', name: 'hand-lizard' },
  { id: 'fas fa-hand-middle-finger', name: 'hand-middle-finger' },
  { id: 'fas fa-hand-paper', name: 'hand-paper' },
  { id: 'fas fa-hand-peace', name: 'hand-peace' },
  { id: 'fas fa-hand-point-down', name: 'hand-point-down' },
  { id: 'fas fa-hand-point-left', name: 'hand-point-left' },
  { id: 'fas fa-hand-point-right', name: 'hand-point-right' },
  { id: 'fas fa-hand-point-up', name: 'hand-point-up' },
  { id: 'fas fa-hand-pointer', name: 'hand-pointer' },
  { id: 'fas fa-hand-rock', name: 'hand-rock' },
  { id: 'fas fa-hand-scissors', name: 'hand-scissors' },
  { id: 'fas fa-hand-sparkles', name: 'hand-sparkles' },
  { id: 'fas fa-hand-spock', name: 'hand-spock' },
  { id: 'fas fa-hands', name: 'hands' },
  { id: 'fas fa-hands-helping', name: 'hands-helping' },
  { id: 'fas fa-hands-wash', name: 'hands-wash' },
  { id: 'fas fa-handshake', name: 'handshake' },
  { id: 'fas fa-handshake-alt-slash', name: 'handshake-alt-slash' },
  { id: 'fas fa-handshake-slash', name: 'handshake-slash' },
  { id: 'fas fa-hanukiah', name: 'hanukiah' },
  { id: 'fas fa-hard-hat', name: 'hard-hat' },
  { id: 'fas fa-hashtag', name: 'hashtag' },
  { id: 'fas fa-hat-cowboy', name: 'hat-cowboy' },
  { id: 'fas fa-hat-cowboy-side', name: 'hat-cowboy-side' },
  { id: 'fas fa-hat-wizard', name: 'hat-wizard' },
  { id: 'fas fa-hdd', name: 'hdd' },
  { id: 'fas fa-head-side-cough', name: 'head-side-cough' },
  { id: 'fas fa-head-side-cough-slash', name: 'head-side-cough-slash' },
  { id: 'fas fa-head-side-mask', name: 'head-side-mask' },
  { id: 'fas fa-head-side-virus', name: 'head-side-virus' },
  { id: 'fas fa-heading', name: 'heading' },
  { id: 'fas fa-headphones', name: 'headphones' },
  { id: 'fas fa-headphones-alt', name: 'headphones-alt' },
  { id: 'fas fa-headset', name: 'headset' },
  { id: 'fas fa-heart', name: 'heart' },
  { id: 'fas fa-heart-broken', name: 'heart-broken' },
  { id: 'fas fa-heartbeat', name: 'heartbeat' },
  { id: 'fas fa-helicopter', name: 'helicopter' },
  { id: 'fas fa-highlighter', name: 'highlighter' },
  { id: 'fas fa-hiking', name: 'hiking' },
  { id: 'fas fa-hippo', name: 'hippo' },
  { id: 'fas fa-history', name: 'history' },
  { id: 'fas fa-hockey-puck', name: 'hockey-puck' },
  { id: 'fas fa-holly-berry', name: 'holly-berry' },
  { id: 'fas fa-home', name: 'home' },
  { id: 'fas fa-horse', name: 'horse' },
  { id: 'fas fa-horse-head', name: 'horse-head' },
  { id: 'fas fa-hospital', name: 'hospital' },
  { id: 'fas fa-hospital-alt', name: 'hospital-alt' },
  { id: 'fas fa-hospital-symbol', name: 'hospital-symbol' },
  { id: 'fas fa-hospital-user', name: 'hospital-user' },
  { id: 'fas fa-hot-tub', name: 'hot-tub' },
  { id: 'fas fa-hotdog', name: 'hotdog' },
  { id: 'fas fa-hotel', name: 'hotel' },
  { id: 'fas fa-hourglass', name: 'hourglass' },
  { id: 'fas fa-hourglass-end', name: 'hourglass-end' },
  { id: 'fas fa-hourglass-half', name: 'hourglass-half' },
  { id: 'fas fa-hourglass-start', name: 'hourglass-start' },
  { id: 'fas fa-house-damage', name: 'house-damage' },
  { id: 'fas fa-house-user', name: 'house-user' },
  { id: 'fas fa-hryvnia', name: 'hryvnia' },
  { id: 'fas fa-i-cursor', name: 'i-cursor' },
  { id: 'fas fa-ice-cream', name: 'ice-cream' },
  { id: 'fas fa-icicles', name: 'icicles' },
  { id: 'fas fa-icons', name: 'icons' },
  { id: 'fas fa-id-badge', name: 'id-badge' },
  { id: 'fas fa-id-card', name: 'id-card' },
  { id: 'fas fa-id-card-alt', name: 'id-card-alt' },
  { id: 'fas fa-igloo', name: 'igloo' },
  { id: 'fas fa-image', name: 'image' },
  { id: 'fas fa-images', name: 'images' },
  { id: 'fas fa-inbox', name: 'inbox' },
  { id: 'fas fa-indent', name: 'indent' },
  { id: 'fas fa-industry', name: 'industry' },
  { id: 'fas fa-infinity', name: 'infinity' },
  { id: 'fas fa-info', name: 'info' },
  { id: 'fas fa-info-circle', name: 'info-circle' },
  { id: 'fas fa-italic', name: 'italic' },
  { id: 'fas fa-jedi', name: 'jedi' },
  { id: 'fas fa-joint', name: 'joint' },
  { id: 'fas fa-journal-whills', name: 'journal-whills' },
  { id: 'fas fa-kaaba', name: 'kaaba' },
  { id: 'fas fa-key', name: 'key' },
  { id: 'fas fa-keyboard', name: 'keyboard' },
  { id: 'fas fa-khanda', name: 'khanda' },
  { id: 'fas fa-kiss', name: 'kiss' },
  { id: 'fas fa-kiss-beam', name: 'kiss-beam' },
  { id: 'fas fa-kiss-wink-heart', name: 'kiss-wink-heart' },
  { id: 'fas fa-kiwi-bird', name: 'kiwi-bird' },
  { id: 'fas fa-landmark', name: 'landmark' },
  { id: 'fas fa-language', name: 'language' },
  { id: 'fas fa-laptop', name: 'laptop' },
  { id: 'fas fa-laptop-code', name: 'laptop-code' },
  { id: 'fas fa-laptop-house', name: 'laptop-house' },
  { id: 'fas fa-laptop-medical', name: 'laptop-medical' },
  { id: 'fas fa-laugh', name: 'laugh' },
  { id: 'fas fa-laugh-beam', name: 'laugh-beam' },
  { id: 'fas fa-laugh-squint', name: 'laugh-squint' },
  { id: 'fas fa-laugh-wink', name: 'laugh-wink' },
  { id: 'fas fa-layer-group', name: 'layer-group' },
  { id: 'fas fa-leaf', name: 'leaf' },
  { id: 'fas fa-lemon', name: 'lemon' },
  { id: 'fas fa-less-than', name: 'less-than' },
  { id: 'fas fa-less-than-equal', name: 'less-than-equal' },
  { id: 'fas fa-level-down-alt', name: 'level-down-alt' },
  { id: 'fas fa-level-up-alt', name: 'level-up-alt' },
  { id: 'fas fa-life-ring', name: 'life-ring' },
  { id: 'fas fa-lightbulb', name: 'lightbulb' },
  { id: 'fas fa-link', name: 'link' },
  { id: 'fas fa-lira-sign', name: 'lira-sign' },
  { id: 'fas fa-list', name: 'list' },
  { id: 'fas fa-list-alt', name: 'list-alt' },
  { id: 'fas fa-list-ol', name: 'list-ol' },
  { id: 'fas fa-list-ul', name: 'list-ul' },
  { id: 'fas fa-location-arrow', name: 'location-arrow' },
  { id: 'fas fa-lock', name: 'lock' },
  { id: 'fas fa-lock-open', name: 'lock-open' },
  { id: 'fas fa-long-arrow-alt-down', name: 'long-arrow-alt-down' },
  { id: 'fas fa-long-arrow-alt-left', name: 'long-arrow-alt-left' },
  { id: 'fas fa-long-arrow-alt-right', name: 'long-arrow-alt-right' },
  { id: 'fas fa-long-arrow-alt-up', name: 'long-arrow-alt-up' },
  { id: 'fas fa-low-vision', name: 'low-vision' },
  { id: 'fas fa-luggage-cart', name: 'luggage-cart' },
  { id: 'fas fa-lungs', name: 'lungs' },
  { id: 'fas fa-lungs-virus', name: 'lungs-virus' },
  { id: 'fas fa-magic', name: 'magic' },
  { id: 'fas fa-magnet', name: 'magnet' },
  { id: 'fas fa-mail-bulk', name: 'mail-bulk' },
  { id: 'fas fa-male', name: 'male' },
  { id: 'fas fa-map', name: 'map' },
  { id: 'fas fa-map-marked', name: 'map-marked' },
  { id: 'fas fa-map-marked-alt', name: 'map-marked-alt' },
  { id: 'fas fa-map-marker', name: 'map-marker' },
  { id: 'fas fa-map-marker-alt', name: 'map-marker-alt' },
  { id: 'fas fa-map-pin', name: 'map-pin' },
  { id: 'fas fa-map-signs', name: 'map-signs' },
  { id: 'fas fa-marker', name: 'marker' },
  { id: 'fas fa-mars', name: 'mars' },
  { id: 'fas fa-mars-double', name: 'mars-double' },
  { id: 'fas fa-mars-stroke', name: 'mars-stroke' },
  { id: 'fas fa-mars-stroke-h', name: 'mars-stroke-h' },
  { id: 'fas fa-mars-stroke-v', name: 'mars-stroke-v' },
  { id: 'fas fa-mask', name: 'mask' },
  { id: 'fas fa-medal', name: 'medal' },
  { id: 'fas fa-medkit', name: 'medkit' },
  { id: 'fas fa-meh', name: 'meh' },
  { id: 'fas fa-meh-blank', name: 'meh-blank' },
  { id: 'fas fa-meh-rolling-eyes', name: 'meh-rolling-eyes' },
  { id: 'fas fa-memory', name: 'memory' },
  { id: 'fas fa-menorah', name: 'menorah' },
  { id: 'fas fa-mercury', name: 'mercury' },
  { id: 'fas fa-meteor', name: 'meteor' },
  { id: 'fas fa-microchip', name: 'microchip' },
  { id: 'fas fa-microphone', name: 'microphone' },
  { id: 'fas fa-microphone-alt', name: 'microphone-alt' },
  { id: 'fas fa-microphone-alt-slash', name: 'microphone-alt-slash' },
  { id: 'fas fa-microphone-slash', name: 'microphone-slash' },
  { id: 'fas fa-microscope', name: 'microscope' },
  { id: 'fas fa-minus', name: 'minus' },
  { id: 'fas fa-minus-circle', name: 'minus-circle' },
  { id: 'fas fa-minus-square', name: 'minus-square' },
  { id: 'fas fa-mitten', name: 'mitten' },
  { id: 'fas fa-mobile', name: 'mobile' },
  { id: 'fas fa-mobile-alt', name: 'mobile-alt' },
  { id: 'fas fa-money-bill', name: 'money-bill' },
  { id: 'fas fa-money-bill-alt', name: 'money-bill-alt' },
  { id: 'fas fa-money-bill-wave', name: 'money-bill-wave' },
  { id: 'fas fa-money-bill-wave-alt', name: 'money-bill-wave-alt' },
  { id: 'fas fa-money-check', name: 'money-check' },
  { id: 'fas fa-money-check-alt', name: 'money-check-alt' },
  { id: 'fas fa-monument', name: 'monument' },
  { id: 'fas fa-moon', name: 'moon' },
  { id: 'fas fa-mortar-pestle', name: 'mortar-pestle' },
  { id: 'fas fa-mosque', name: 'mosque' },
  { id: 'fas fa-motorcycle', name: 'motorcycle' },
  { id: 'fas fa-mountain', name: 'mountain' },
  { id: 'fas fa-mouse', name: 'mouse' },
  { id: 'fas fa-mouse-pointer', name: 'mouse-pointer' },
  { id: 'fas fa-mug-hot', name: 'mug-hot' },
  { id: 'fas fa-music', name: 'music' },
  { id: 'fas fa-network-wired', name: 'network-wired' },
  { id: 'fas fa-neuter', name: 'neuter' },
  { id: 'fas fa-newspaper', name: 'newspaper' },
  { id: 'fas fa-not-equal', name: 'not-equal' },
  { id: 'fas fa-notes-medical', name: 'notes-medical' },
  { id: 'fas fa-object-group', name: 'object-group' },
  { id: 'fas fa-object-ungroup', name: 'object-ungroup' },
  { id: 'fas fa-oil-can', name: 'oil-can' },
  { id: 'fas fa-om', name: 'om' },
  { id: 'fas fa-otter', name: 'otter' },
  { id: 'fas fa-outdent', name: 'outdent' },
  { id: 'fas fa-pager', name: 'pager' },
  { id: 'fas fa-paint-brush', name: 'paint-brush' },
  { id: 'fas fa-paint-roller', name: 'paint-roller' },
  { id: 'fas fa-palette', name: 'palette' },
  { id: 'fas fa-pallet', name: 'pallet' },
  { id: 'fas fa-paper-plane', name: 'paper-plane' },
  { id: 'fas fa-paperclip', name: 'paperclip' },
  { id: 'fas fa-parachute-box', name: 'parachute-box' },
  { id: 'fas fa-paragraph', name: 'paragraph' },
  { id: 'fas fa-parking', name: 'parking' },
  { id: 'fas fa-passport', name: 'passport' },
  { id: 'fas fa-pastafarianism', name: 'pastafarianism' },
  { id: 'fas fa-paste', name: 'paste' },
  { id: 'fas fa-pause', name: 'pause' },
  { id: 'fas fa-pause-circle', name: 'pause-circle' },
  { id: 'fas fa-paw', name: 'paw' },
  { id: 'fas fa-peace', name: 'peace' },
  { id: 'fas fa-pen', name: 'pen' },
  { id: 'fas fa-pen-alt', name: 'pen-alt' },
  { id: 'fas fa-pen-fancy', name: 'pen-fancy' },
  { id: 'fas fa-pen-nib', name: 'pen-nib' },
  { id: 'fas fa-pen-square', name: 'pen-square' },
  { id: 'fas fa-pencil-alt', name: 'pencil-alt' },
  { id: 'fas fa-pencil-ruler', name: 'pencil-ruler' },
  { id: 'fas fa-people-arrows', name: 'people-arrows' },
  { id: 'fas fa-people-carry', name: 'people-carry' },
  { id: 'fas fa-pepper-hot', name: 'pepper-hot' },
  { id: 'fas fa-percent', name: 'percent' },
  { id: 'fas fa-percentage', name: 'percentage' },
  { id: 'fas fa-person-booth', name: 'person-booth' },
  { id: 'fas fa-phone', name: 'phone' },
  { id: 'fas fa-phone-alt', name: 'phone-alt' },
  { id: 'fas fa-phone-slash', name: 'phone-slash' },
  { id: 'fas fa-phone-square', name: 'phone-square' },
  { id: 'fas fa-phone-square-alt', name: 'phone-square-alt' },
  { id: 'fas fa-phone-volume', name: 'phone-volume' },
  { id: 'fas fa-photo-video', name: 'photo-video' },
  { id: 'fas fa-piggy-bank', name: 'piggy-bank' },
  { id: 'fas fa-pills', name: 'pills' },
  { id: 'fas fa-pizza-slice', name: 'pizza-slice' },
  { id: 'fas fa-place-of-worship', name: 'place-of-worship' },
  { id: 'fas fa-plane', name: 'plane' },
  { id: 'fas fa-plane-arrival', name: 'plane-arrival' },
  { id: 'fas fa-plane-departure', name: 'plane-departure' },
  { id: 'fas fa-plane-slash', name: 'plane-slash' },
  { id: 'fas fa-play', name: 'play' },
  { id: 'fas fa-play-circle', name: 'play-circle' },
  { id: 'fas fa-plug', name: 'plug' },
  { id: 'fas fa-plus', name: 'plus' },
  { id: 'fas fa-plus-circle', name: 'plus-circle' },
  { id: 'fas fa-plus-square', name: 'plus-square' },
  { id: 'fas fa-podcast', name: 'podcast' },
  { id: 'fas fa-poll', name: 'poll' },
  { id: 'fas fa-poll-h', name: 'poll-h' },
  { id: 'fas fa-poo', name: 'poo' },
  { id: 'fas fa-poo-storm', name: 'poo-storm' },
  { id: 'fas fa-poop', name: 'poop' },
  { id: 'fas fa-portrait', name: 'portrait' },
  { id: 'fas fa-pound-sign', name: 'pound-sign' },
  { id: 'fas fa-power-off', name: 'power-off' },
  { id: 'fas fa-pray', name: 'pray' },
  { id: 'fas fa-praying-hands', name: 'praying-hands' },
  { id: 'fas fa-prescription', name: 'prescription' },
  { id: 'fas fa-prescription-bottle', name: 'prescription-bottle' },
  { id: 'fas fa-prescription-bottle-alt', name: 'prescription-bottle-alt' },
  { id: 'fas fa-print', name: 'print' },
  { id: 'fas fa-procedures', name: 'procedures' },
  { id: 'fas fa-project-diagram', name: 'project-diagram' },
  { id: 'fas fa-pump-medical', name: 'pump-medical' },
  { id: 'fas fa-pump-soap', name: 'pump-soap' },
  { id: 'fas fa-puzzle-piece', name: 'puzzle-piece' },
  { id: 'fas fa-qrcode', name: 'qrcode' },
  { id: 'fas fa-question', name: 'question' },
  { id: 'fas fa-question-circle', name: 'question-circle' },
  { id: 'fas fa-quidditch', name: 'quidditch' },
  { id: 'fas fa-quote-left', name: 'quote-left' },
  { id: 'fas fa-quote-right', name: 'quote-right' },
  { id: 'fas fa-quran', name: 'quran' },
  { id: 'fas fa-radiation', name: 'radiation' },
  { id: 'fas fa-radiation-alt', name: 'radiation-alt' },
  { id: 'fas fa-rainbow', name: 'rainbow' },
  { id: 'fas fa-random', name: 'random' },
  { id: 'fas fa-receipt', name: 'receipt' },
  { id: 'fas fa-record-vinyl', name: 'record-vinyl' },
  { id: 'fas fa-recycle', name: 'recycle' },
  { id: 'fas fa-redo', name: 'redo' },
  { id: 'fas fa-redo-alt', name: 'redo-alt' },
  { id: 'fas fa-registered', name: 'registered' },
  { id: 'fas fa-remove-format', name: 'remove-format' },
  { id: 'fas fa-reply', name: 'reply' },
  { id: 'fas fa-reply-all', name: 'reply-all' },
  { id: 'fas fa-republican', name: 'republican' },
  { id: 'fas fa-restroom', name: 'restroom' },
  { id: 'fas fa-retweet', name: 'retweet' },
  { id: 'fas fa-ribbon', name: 'ribbon' },
  { id: 'fas fa-ring', name: 'ring' },
  { id: 'fas fa-road', name: 'road' },
  { id: 'fas fa-robot', name: 'robot' },
  { id: 'fas fa-rocket', name: 'rocket' },
  { id: 'fas fa-route', name: 'route' },
  { id: 'fas fa-rss', name: 'rss' },
  { id: 'fas fa-rss-square', name: 'rss-square' },
  { id: 'fas fa-ruble-sign', name: 'ruble-sign' },
  { id: 'fas fa-ruler', name: 'ruler' },
  { id: 'fas fa-ruler-combined', name: 'ruler-combined' },
  { id: 'fas fa-ruler-horizontal', name: 'ruler-horizontal' },
  { id: 'fas fa-ruler-vertical', name: 'ruler-vertical' },
  { id: 'fas fa-running', name: 'running' },
  { id: 'fas fa-rupee-sign', name: 'rupee-sign' },
  { id: 'fas fa-sad-cry', name: 'sad-cry' },
  { id: 'fas fa-sad-tear', name: 'sad-tear' },
  { id: 'fas fa-satellite', name: 'satellite' },
  { id: 'fas fa-satellite-dish', name: 'satellite-dish' },
  { id: 'fas fa-save', name: 'save' },
  { id: 'fas fa-school', name: 'school' },
  { id: 'fas fa-screwdriver', name: 'screwdriver' },
  { id: 'fas fa-scroll', name: 'scroll' },
  { id: 'fas fa-sd-card', name: 'sd-card' },
  { id: 'fas fa-search', name: 'search' },
  { id: 'fas fa-search-dollar', name: 'search-dollar' },
  { id: 'fas fa-search-location', name: 'search-location' },
  { id: 'fas fa-search-minus', name: 'search-minus' },
  { id: 'fas fa-search-plus', name: 'search-plus' },
  { id: 'fas fa-seedling', name: 'seedling' },
  { id: 'fas fa-server', name: 'server' },
  { id: 'fas fa-shapes', name: 'shapes' },
  { id: 'fas fa-share', name: 'share' },
  { id: 'fas fa-share-alt', name: 'share-alt' },
  { id: 'fas fa-share-alt-square', name: 'share-alt-square' },
  { id: 'fas fa-share-square', name: 'share-square' },
  { id: 'fas fa-shekel-sign', name: 'shekel-sign' },
  { id: 'fas fa-shield-alt', name: 'shield-alt' },
  { id: 'fas fa-shield-virus', name: 'shield-virus' },
  { id: 'fas fa-ship', name: 'ship' },
  { id: 'fas fa-shipping-fast', name: 'shipping-fast' },
  { id: 'fas fa-shoe-prints', name: 'shoe-prints' },
  { id: 'fas fa-shopping-bag', name: 'shopping-bag' },
  { id: 'fas fa-shopping-basket', name: 'shopping-basket' },
  { id: 'fas fa-shopping-cart', name: 'shopping-cart' },
  { id: 'fas fa-shower', name: 'shower' },
  { id: 'fas fa-shuttle-van', name: 'shuttle-van' },
  { id: 'fas fa-sign', name: 'sign' },
  { id: 'fas fa-sign-in-alt', name: 'sign-in-alt' },
  { id: 'fas fa-sign-language', name: 'sign-language' },
  { id: 'fas fa-sign-out-alt', name: 'sign-out-alt' },
  { id: 'fas fa-signal', name: 'signal' },
  { id: 'fas fa-signature', name: 'signature' },
  { id: 'fas fa-sim-card', name: 'sim-card' },
  { id: 'fas fa-sink', name: 'sink' },
  { id: 'fas fa-sitemap', name: 'sitemap' },
  { id: 'fas fa-skating', name: 'skating' },
  { id: 'fas fa-skiing', name: 'skiing' },
  { id: 'fas fa-skiing-nordic', name: 'skiing-nordic' },
  { id: 'fas fa-skull', name: 'skull' },
  { id: 'fas fa-skull-crossbones', name: 'skull-crossbones' },
  { id: 'fas fa-slash', name: 'slash' },
  { id: 'fas fa-sleigh', name: 'sleigh' },
  { id: 'fas fa-sliders-h', name: 'sliders-h' },
  { id: 'fas fa-smile', name: 'smile' },
  { id: 'fas fa-smile-beam', name: 'smile-beam' },
  { id: 'fas fa-smile-wink', name: 'smile-wink' },
  { id: 'fas fa-smog', name: 'smog' },
  { id: 'fas fa-smoking', name: 'smoking' },
  { id: 'fas fa-smoking-ban', name: 'smoking-ban' },
  { id: 'fas fa-sms', name: 'sms' },
  { id: 'fas fa-snowboarding', name: 'snowboarding' },
  { id: 'fas fa-snowflake', name: 'snowflake' },
  { id: 'fas fa-snowman', name: 'snowman' },
  { id: 'fas fa-snowplow', name: 'snowplow' },
  { id: 'fas fa-soap', name: 'soap' },
  { id: 'fas fa-socks', name: 'socks' },
  { id: 'fas fa-solar-panel', name: 'solar-panel' },
  { id: 'fas fa-sort', name: 'sort' },
  { id: 'fas fa-sort-alpha-down', name: 'sort-alpha-down' },
  { id: 'fas fa-sort-alpha-down-alt', name: 'sort-alpha-down-alt' },
  { id: 'fas fa-sort-alpha-up', name: 'sort-alpha-up' },
  { id: 'fas fa-sort-alpha-up-alt', name: 'sort-alpha-up-alt' },
  { id: 'fas fa-sort-amount-down', name: 'sort-amount-down' },
  { id: 'fas fa-sort-amount-down-alt', name: 'sort-amount-down-alt' },
  { id: 'fas fa-sort-amount-up', name: 'sort-amount-up' },
  { id: 'fas fa-sort-amount-up-alt', name: 'sort-amount-up-alt' },
  { id: 'fas fa-sort-down', name: 'sort-down' },
  { id: 'fas fa-sort-numeric-down', name: 'sort-numeric-down' },
  { id: 'fas fa-sort-numeric-down-alt', name: 'sort-numeric-down-alt' },
  { id: 'fas fa-sort-numeric-up', name: 'sort-numeric-up' },
  { id: 'fas fa-sort-numeric-up-alt', name: 'sort-numeric-up-alt' },
  { id: 'fas fa-sort-up', name: 'sort-up' },
  { id: 'fas fa-spa', name: 'spa' },
  { id: 'fas fa-space-shuttle', name: 'space-shuttle' },
  { id: 'fas fa-spell-check', name: 'spell-check' },
  { id: 'fas fa-spider', name: 'spider' },
  { id: 'fas fa-spinner', name: 'spinner' },
  { id: 'fas fa-splotch', name: 'splotch' },
  { id: 'fas fa-spray-can', name: 'spray-can' },
  { id: 'fas fa-square', name: 'square' },
  { id: 'fas fa-square-full', name: 'square-full' },
  { id: 'fas fa-square-root-alt', name: 'square-root-alt' },
  { id: 'fas fa-stamp', name: 'stamp' },
  { id: 'fas fa-star', name: 'star' },
  { id: 'fas fa-star-and-crescent', name: 'star-and-crescent' },
  { id: 'fas fa-star-half', name: 'star-half' },
  { id: 'fas fa-star-half-alt', name: 'star-half-alt' },
  { id: 'fas fa-star-of-david', name: 'star-of-david' },
  { id: 'fas fa-star-of-life', name: 'star-of-life' },
  { id: 'fas fa-step-backward', name: 'step-backward' },
  { id: 'fas fa-step-forward', name: 'step-forward' },
  { id: 'fas fa-stethoscope', name: 'stethoscope' },
  { id: 'fas fa-sticky-note', name: 'sticky-note' },
  { id: 'fas fa-stop', name: 'stop' },
  { id: 'fas fa-stop-circle', name: 'stop-circle' },
  { id: 'fas fa-stopwatch', name: 'stopwatch' },
  { id: 'fas fa-stopwatch-20', name: 'stopwatch-20' },
  { id: 'fas fa-store', name: 'store' },
  { id: 'fas fa-store-alt', name: 'store-alt' },
  { id: 'fas fa-store-alt-slash', name: 'store-alt-slash' },
  { id: 'fas fa-store-slash', name: 'store-slash' },
  { id: 'fas fa-stream', name: 'stream' },
  { id: 'fas fa-street-view', name: 'street-view' },
  { id: 'fas fa-strikethrough', name: 'strikethrough' },
  { id: 'fas fa-stroopwafel', name: 'stroopwafel' },
  { id: 'fas fa-subscript', name: 'subscript' },
  { id: 'fas fa-subway', name: 'subway' },
  { id: 'fas fa-suitcase', name: 'suitcase' },
  { id: 'fas fa-suitcase-rolling', name: 'suitcase-rolling' },
  { id: 'fas fa-sun', name: 'sun' },
  { id: 'fas fa-superscript', name: 'superscript' },
  { id: 'fas fa-surprise', name: 'surprise' },
  { id: 'fas fa-swatchbook', name: 'swatchbook' },
  { id: 'fas fa-swimmer', name: 'swimmer' },
  { id: 'fas fa-swimming-pool', name: 'swimming-pool' },
  { id: 'fas fa-synagogue', name: 'synagogue' },
  { id: 'fas fa-sync', name: 'sync' },
  { id: 'fas fa-sync-alt', name: 'sync-alt' },
  { id: 'fas fa-syringe', name: 'syringe' },
  { id: 'fas fa-table', name: 'table' },
  { id: 'fas fa-table-tennis', name: 'table-tennis' },
  { id: 'fas fa-tablet', name: 'tablet' },
  { id: 'fas fa-tablet-alt', name: 'tablet-alt' },
  { id: 'fas fa-tablets', name: 'tablets' },
  { id: 'fas fa-tachometer-alt', name: 'tachometer-alt' },
  { id: 'fas fa-tag', name: 'tag' },
  { id: 'fas fa-tags', name: 'tags' },
  { id: 'fas fa-tape', name: 'tape' },
  { id: 'fas fa-tasks', name: 'tasks' },
  { id: 'fas fa-taxi', name: 'taxi' },
  { id: 'fas fa-teeth', name: 'teeth' },
  { id: 'fas fa-teeth-open', name: 'teeth-open' },
  { id: 'fas fa-temperature-high', name: 'temperature-high' },
  { id: 'fas fa-temperature-low', name: 'temperature-low' },
  { id: 'fas fa-tenge', name: 'tenge' },
  { id: 'fas fa-terminal', name: 'terminal' },
  { id: 'fas fa-text-height', name: 'text-height' },
  { id: 'fas fa-text-width', name: 'text-width' },
  { id: 'fas fa-th', name: 'th' },
  { id: 'fas fa-th-large', name: 'th-large' },
  { id: 'fas fa-th-list', name: 'th-list' },
  { id: 'fas fa-theater-masks', name: 'theater-masks' },
  { id: 'fas fa-thermometer', name: 'thermometer' },
  { id: 'fas fa-thermometer-empty', name: 'thermometer-empty' },
  { id: 'fas fa-thermometer-full', name: 'thermometer-full' },
  { id: 'fas fa-thermometer-half', name: 'thermometer-half' },
  { id: 'fas fa-thermometer-quarter', name: 'thermometer-quarter' },
  {
    id: 'fas fa-thermometer-three-quarters',
    name: 'thermometer-three-quarters',
  },
  { id: 'fas fa-thumbs-down', name: 'thumbs-down' },
  { id: 'fas fa-thumbs-up', name: 'thumbs-up' },
  { id: 'fas fa-thumbtack', name: 'thumbtack' },
  { id: 'fas fa-ticket-alt', name: 'ticket-alt' },
  { id: 'fas fa-times', name: 'times' },
  { id: 'fas fa-times-circle', name: 'times-circle' },
  { id: 'fas fa-tint', name: 'tint' },
  { id: 'fas fa-tint-slash', name: 'tint-slash' },
  { id: 'fas fa-tired', name: 'tired' },
  { id: 'fas fa-toggle-off', name: 'toggle-off' },
  { id: 'fas fa-toggle-on', name: 'toggle-on' },
  { id: 'fas fa-toilet', name: 'toilet' },
  { id: 'fas fa-toilet-paper', name: 'toilet-paper' },
  { id: 'fas fa-toilet-paper-slash', name: 'toilet-paper-slash' },
  { id: 'fas fa-toolbox', name: 'toolbox' },
  { id: 'fas fa-tools', name: 'tools' },
  { id: 'fas fa-tooth', name: 'tooth' },
  { id: 'fas fa-torah', name: 'torah' },
  { id: 'fas fa-torii-gate', name: 'torii-gate' },
  { id: 'fas fa-tractor', name: 'tractor' },
  { id: 'fas fa-trademark', name: 'trademark' },
  { id: 'fas fa-traffic-light', name: 'traffic-light' },
  { id: 'fas fa-trailer', name: 'trailer' },
  { id: 'fas fa-train', name: 'train' },
  { id: 'fas fa-tram', name: 'tram' },
  { id: 'fas fa-transgender', name: 'transgender' },
  { id: 'fas fa-transgender-alt', name: 'transgender-alt' },
  { id: 'fas fa-trash', name: 'trash' },
  { id: 'fas fa-trash-alt', name: 'trash-alt' },
  { id: 'fas fa-trash-restore', name: 'trash-restore' },
  { id: 'fas fa-trash-restore-alt', name: 'trash-restore-alt' },
  { id: 'fas fa-tree', name: 'tree' },
  { id: 'fas fa-trophy', name: 'trophy' },
  { id: 'fas fa-truck', name: 'truck' },
  { id: 'fas fa-truck-loading', name: 'truck-loading' },
  { id: 'fas fa-truck-monster', name: 'truck-monster' },
  { id: 'fas fa-truck-moving', name: 'truck-moving' },
  { id: 'fas fa-truck-pickup', name: 'truck-pickup' },
  { id: 'fas fa-tshirt', name: 'tshirt' },
  { id: 'fas fa-tty', name: 'tty' },
  { id: 'fas fa-tv', name: 'tv' },
  { id: 'fas fa-umbrella', name: 'umbrella' },
  { id: 'fas fa-umbrella-beach', name: 'umbrella-beach' },
  { id: 'fas fa-underline', name: 'underline' },
  { id: 'fas fa-undo', name: 'undo' },
  { id: 'fas fa-undo-alt', name: 'undo-alt' },
  { id: 'fas fa-universal-access', name: 'universal-access' },
  { id: 'fas fa-university', name: 'university' },
  { id: 'fas fa-unlink', name: 'unlink' },
  { id: 'fas fa-unlock', name: 'unlock' },
  { id: 'fas fa-unlock-alt', name: 'unlock-alt' },
  { id: 'fas fa-upload', name: 'upload' },
  { id: 'fas fa-user', name: 'user' },
  { id: 'fas fa-user-alt', name: 'user-alt' },
  { id: 'fas fa-user-alt-slash', name: 'user-alt-slash' },
  { id: 'fas fa-user-astronaut', name: 'user-astronaut' },
  { id: 'fas fa-user-check', name: 'user-check' },
  { id: 'fas fa-user-circle', name: 'user-circle' },
  { id: 'fas fa-user-clock', name: 'user-clock' },
  { id: 'fas fa-user-cog', name: 'user-cog' },
  { id: 'fas fa-user-edit', name: 'user-edit' },
  { id: 'fas fa-user-friends', name: 'user-friends' },
  { id: 'fas fa-user-graduate', name: 'user-graduate' },
  { id: 'fas fa-user-injured', name: 'user-injured' },
  { id: 'fas fa-user-lock', name: 'user-lock' },
  { id: 'fas fa-user-md', name: 'user-md' },
  { id: 'fas fa-user-minus', name: 'user-minus' },
  { id: 'fas fa-user-ninja', name: 'user-ninja' },
  { id: 'fas fa-user-nurse', name: 'user-nurse' },
  { id: 'fas fa-user-plus', name: 'user-plus' },
  { id: 'fas fa-user-secret', name: 'user-secret' },
  { id: 'fas fa-user-shield', name: 'user-shield' },
  { id: 'fas fa-user-slash', name: 'user-slash' },
  { id: 'fas fa-user-tag', name: 'user-tag' },
  { id: 'fas fa-user-tie', name: 'user-tie' },
  { id: 'fas fa-user-times', name: 'user-times' },
  { id: 'fas fa-users', name: 'users' },
  { id: 'fas fa-users-cog', name: 'users-cog' },
  { id: 'fas fa-users-slash', name: 'users-slash' },
  { id: 'fas fa-utensil-spoon', name: 'utensil-spoon' },
  { id: 'fas fa-utensils', name: 'utensils' },
  { id: 'fas fa-vector-square', name: 'vector-square' },
  { id: 'fas fa-venus', name: 'venus' },
  { id: 'fas fa-venus-double', name: 'venus-double' },
  { id: 'fas fa-venus-mars', name: 'venus-mars' },
  { id: 'fas fa-vial', name: 'vial' },
  { id: 'fas fa-vials', name: 'vials' },
  { id: 'fas fa-video', name: 'video' },
  { id: 'fas fa-video-slash', name: 'video-slash' },
  { id: 'fas fa-vihara', name: 'vihara' },
  { id: 'fas fa-virus', name: 'virus' },
  { id: 'fas fa-virus-slash', name: 'virus-slash' },
  { id: 'fas fa-viruses', name: 'viruses' },
  { id: 'fas fa-voicemail', name: 'voicemail' },
  { id: 'fas fa-volleyball-ball', name: 'volleyball-ball' },
  { id: 'fas fa-volume-down', name: 'volume-down' },
  { id: 'fas fa-volume-mute', name: 'volume-mute' },
  { id: 'fas fa-volume-off', name: 'volume-off' },
  { id: 'fas fa-volume-up', name: 'volume-up' },
  { id: 'fas fa-vote-yea', name: 'vote-yea' },
  { id: 'fas fa-vr-cardboard', name: 'vr-cardboard' },
  { id: 'fas fa-walking', name: 'walking' },
  { id: 'fas fa-wallet', name: 'wallet' },
  { id: 'fas fa-warehouse', name: 'warehouse' },
  { id: 'fas fa-water', name: 'water' },
  { id: 'fas fa-wave-square', name: 'wave-square' },
  { id: 'fas fa-weight', name: 'weight' },
  { id: 'fas fa-weight-hanging', name: 'weight-hanging' },
  { id: 'fas fa-wheelchair', name: 'wheelchair' },
  { id: 'fas fa-wifi', name: 'wifi' },
  { id: 'fas fa-wind', name: 'wind' },
  { id: 'fas fa-window-close', name: 'window-close' },
  { id: 'fas fa-window-maximize', name: 'window-maximize' },
  { id: 'fas fa-window-minimize', name: 'window-minimize' },
  { id: 'fas fa-window-restore', name: 'window-restore' },
  { id: 'fas fa-wine-bottle', name: 'wine-bottle' },
  { id: 'fas fa-wine-glass', name: 'wine-glass' },
  { id: 'fas fa-wine-glass-alt', name: 'wine-glass-alt' },
  { id: 'fas fa-won-sign', name: 'won-sign' },
  { id: 'fas fa-wrench', name: 'wrench' },
  { id: 'fas fa-x-ray', name: 'x-ray' },
  { id: 'fas fa-yen-sign', name: 'yen-sign' },
  { id: 'fas fa-yin-yang', name: 'yin-yang' },
];
